<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="overview"></company-submenu>

    <div class="submenu-content">
      <p class="h1">Overview</p>
      <img :src="item" :height="400" />
      <br /><br /><br />
      <p class="content">
        For the last four decades, Ma Labs has built an impeccable reputation
        for being one of the most trusted and reliable IT distributors of
        computer and IT components in the world. Headquartered in Silicon
        Valley, and with 8 other hubs strategically located throughout the world, our
        diversified and expansive array of hardware and software technologies
        can be delivered to wholesalers, resellers, and businesses in the most
        expedient amount of time. Our extensive distribution experience and
        commitment to outstanding customer care has yielded multiple awards from
        numerous publications for superior service. Ma Labs delivers an
        outstanding partner experience on every instance by playing a key role
        and being a driving force in helping our clients maximize the return on
        their technical investments and achieve the results they require.
      </p>
      <br /><br /><br />
      <p class="h1">Why Ma Labs?</p>
      <p class="h2">Premier International IT Distributor</p>
      <p class="content">
        Since 1983, Ma Labs has grown to become an IT distributor with an
        expansive catalog of over 10,000 SKUs and we have established
        partnerships with the world’s leading manufacturers of computer
        components and technology. Our comprehensive list includes CPUs, Storage
        Devices, Notebooks, Motherboards, Graphics Cards, Displays, Networking,
        Cases, Input Devices, Software, Image Solutions, Consumer Electronics,
        and more. Ma Labs is also recognized as a leading authorized distributor
        for the major brands, such as ASUS, GIGABYTE, LG Electronics, Micron,
        Microsoft, MSI, NVIDIA, Samsung, Seagate, Super Talent, Synology, and
        Toshiba. We are recognized as the IT Distributor of Choice for providing
        world class service to a wide array of Value-Added Resellers, Systems
        Integrators, OEMs, governmental agencies, and academic institutions
        alike. We are passionate about helping our clients to achieve their
        goals through the adoption of technology resulting in an outstanding
        partner experience every time. At Ma Labs, our competitive advantage is
        our people. We have trained account managers and customer service
        specialists standing by to respond to your inquiries and can ensure
        accurate, just-in-time delivery from one of our strategically located
        distribution hubs using the strongest shipping security protocols.
      </p>
      <p class="h2">Systems Integration</p>
      <p class="content">
        Ma Labs specializes in guiding our clients through the process of
        modernizing their IT infrastructure and helping them achieve a more
        fluid and profitable business operation. We constantly study industry
        trends and adopt new practices so that we can provide our clients with
        the most innovative, cutting-edge technology solutions capable of
        enhancing the performance of their business. aWe also provide
        exceptional levels of technical support to back it all up. Offering
        customized systems integration and configuration solutions is at the
        heart of what we do as a top-rated IT distributor.
      </p>
      <p class="h2">Server System Solutions</p>
      <p class="content">
        Our Server System Solution experts play a crucial role in our client
        relationships. Our experts help our clients build a clear understanding
        of their IT environment by investing the time to help guide our clients
        through the process of designing and implementing a fully customizable
        and scalable server solution that is specifically built to accelerate
        the performance of their business. And we can do it all at a remarkable
        price. Our services include consultations on setting up custom
        partitions, RAID configurations and any other software service request
        that we can help our clients with. Our turnkey solutions also help scale
        from workstation to supercomputer with planning and services available.
        We consistently collaborate with our valuable manufacturing partners to
        offer OEM/ODM server solutions to clients who demand unique system
        solutions that will allow them to stand head-and-shoulders above their
        competition. Visit<router-link to="/solutions/server-solutions" target="blank">
          www.malabs.com/solutions/server-solutions</router-link>
        for more information.
      </p>
      <p class="h2">Outstanding Technical Support</p>
      <p class="content">
        Over the years, Ma Labs invests significantly in building an
        exceptionally talented team of technical support professionals who are
        equipped with extensive technical knowledge and superior communication
        skills. They have the training and expertise to support our clients on a
        wide range of technical issues including component-level
        troubleshooting, system assembly, system restoration, software/hardware
        installation, and device functionality verification.
      </p>
      <p class="h2">Award Winning Recognition & History</p>
      <p class="content">
        Our reputation as one of the leading IT distributors is built on a
        foundation of consistently and successfully working with our clients and
        partners to adopt technologies that accelerate the production of their
        business. Our history in the industry helps us achieve formal
        recognition from numerous publications including Forbes magazine and
        Silicon Valley as well as other industry-leading vendors. At Ma Labs,
        we’ve come to think of ourselves as a forward-thinking and innovative
        business partner with the talent and expertise to help resellers,
        wholesalers, and businesses maximize the value receive from their IT
        investments.
      </p>
      <p class="h2">Financial Options</p>
      <p class="content">
        We offer clients a wide range of accommodating payment methods & terms
        to fit almost any budget. Additionally, we offer a tailored corporate
        financing option (flooring plan) so you can achieve success in this
        challenging and competitive market.
      </p>
      <p class="h2">Educational Events</p>
      <p class="content">
        Ma Labs seeks to build long-term relationships with our customers and
        provide the access to achieve your goals. In addition to keeping up with
        technology trends, having a reliable and professional distributor like
        Ma Labs is essential to succeed in the fast-paced world of technology.
        Throughout the year, we hold various events to provide resources to
        learn the latest industry trends, such as the Partner Tech Fair,
        Reseller Webinars, etc.
      </p>
    </div>
  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from "../../utils/axios-api";
import { ref, onMounted } from "vue";

export default {
  name: "About",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const item = ref({});

    //breadcrumb
    const breadcrumb_links = ref([]);
    breadcrumb_links.value.push({ text: "Company", link: "" });
    breadcrumb_links.value.push({ text: "Overview", link: "About" });

    onMounted(() => {
      getAPI.get("/content/pages/9").then((response) => {
        item.value = response.data.image_display.url;
        item.value = item.value;
      });
    });

    return { item, breadcrumb_links };
  },
};
</script>

<style scoped>
.content {
  padding-bottom: 1%;
  padding-left: 0%;
}
</style>
